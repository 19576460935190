import {
  COMMON_GET_NOTIFICATIONS_SUCCESS,
  COMMON_GET_NOTIFICATIONS_FAILED,
  ADD_NEW_NOTIFICATION,
} from './constants';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import {
  fetchNotifications,
  dismissNotificationRequest,
  dismissAllNotificationsRequest,
  deleteNotificationRequest,
} from '../NotificationAxios';
import eurekaMgrs from '@eureka/ui-managers';
const { eventBus } = eurekaMgrs;

let __dispatch = null;
let __websocket = null;

function onMessage(evt) {
  if (__dispatch) {
    fetchNotifications();
  }
}

function fetchNotificationAction() {
  fetchNotifications().then(
    (res) => {
      __dispatch({
        type: COMMON_GET_NOTIFICATIONS_SUCCESS,
        data: (res && res.data) || [],
      });
      return res;
    },
    (err) => {
      __dispatch({
        type: COMMON_GET_NOTIFICATIONS_FAILED,
        data: err,
      });
      return err;
    },
  );
}

export function startWebsocket() {
  return (dispatch) => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    if (!__websocket) {
      const protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      const url = `${protocol}://${window.location.host}/api/rm-notification-push/`;
      __websocket = new HeartBeatWebSocket(url, onMessage, fetchNotificationAction).start();
    }

    fetchNotifications();

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      __websocket && __websocket.close();
    });
  };
}

export function dismissNotification(notification) {
  return (disptach) =>
    dismissNotificationRequest(notification).finally(() => {
      fetchNotificationAction();
    });
}

export function dismissAllNotifications(notifications) {
  return (disptach) =>
    dismissAllNotificationsRequest(notifications).finally(() => {
      fetchNotificationAction();
    });
}

export function deleteNotification(notification) {
  return (disptach) =>
    deleteNotificationRequest().finally(() => {
      fetchNotificationAction();
    });
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: [],
      };
    case ADD_NEW_NOTIFICATION:
      return { ...state, notification: [...state.notification, action.jsonData] };
    default:
      return state;
  }
}
