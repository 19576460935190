export const devUrl =
  'https://webassistant.enable-now.cloud.sap/wa_cfg/sap_data_ingestion_for_industry_cloud_solutions_internal/WebAssistant.js ';

export const prodUrl =
  'https://webassistant.enable-now.cloud.sap/wa_cfg/data_ingestion_for_industry_cloud_solutions_productive/WebAssistant.js ';

// istanbul ignore next
export const getWebAssistantUrl = () => {
  switch (window.location.hostname) {
    // dev env
    case 'localhost':
    // for multi backend testing
    case 'di.data-dev1.cs-dev.eurekacloud.io':
    case 'di.cs-dev.cs-dev.eurekacloud.io':
    case 'di.cs-sit.cs-dev.eurekacloud.io':
      return devUrl;
    default:
      return prodUrl;
  }
};
