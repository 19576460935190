import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { IllustratedMessage, IllustrationMessageType } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-fiori/dist/illustrations/PageNotFound';
import { Spinner, getConfig } from '../../common/eureka';
import { Header, SidePanel } from '../common';
import { createUseStyles } from 'react-jss';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';

const styles = () => ({
  errorPage: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'var(--sapShellColor)'
  },
});
const useStyles = createUseStyles(styles);

const PageNotFound = (props) => {
  const classes = useStyles();
  const hideNav = getConfig('isDispositionOnly');

  const { showMenu } = useSelector((state) => state.common);

  return (
    <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
      <div className="shell-header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            eventBus={props.eventBus}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          {hideNav ? null : (
            <div
              className={
                'fd-app__navigation fd-app__navigation--vertical' +
                (showMenu ? ' show-menu ' : ' hide-menu ')
              }
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel history={props.history} match={props.match} config={props.config} />
              </Suspense>
            </div>
          )}
          <main className="fd-app__main">
            <div data-testid="errorpage-wrapper" className={classes.errorPage}>
              <IllustratedMessage name={IllustrationMessageType.PageNotFound} />
            </div>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
    </div>
  );
};

export default PageNotFound;
