import React from 'react';
import {
  NotificationListItem,
  Priority,
  Link,
  FlexBox,
  Avatar,
  Icon,
} from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'src/common/eureka';
import moment from 'moment';
import { WrappingType } from '@ui5/webcomponents-react/dist/enums';
// import moment from 'moment';
/**
 *
 *
 Range  Key  Sample Output
 0 to 44 seconds  s  a few seconds ago
 unset  ss  44 seconds ago
 45 to 89 seconds  m  a minute ago
 90 seconds to 44 minutes  mm  2 minutes ago ... 44 minutes ago
 45 to 89 minutes  h  an hour ago
 90 minutes to 21 hours  hh  2 hours ago ... 21 hours ago
 22 to 35 hours  d  a day ago
 36 hours to 25 days  dd  2 days ago ... 25 days ago
 26 to 45 days  M  a month ago
 45 to 319 days  MM  2 months ago ... 10 months ago
 320 to 547 days (1.5 years)  y  a year ago
 548 days+  yy  2 years ago ... 20 years ago
 *
 */
// const Ranges = [/a few seconds ago/gi, /44 seconds ago/gi];

const Second = 1000;
const Minute = 60 * Second;
const Hour = 60 * Minute;
const Day = 24 * Hour;
const Year = 365 * Day;

const styles = () => ({
  notificationStyle: {
    width: '400px',
    position: 'relative',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '0.25rem',
    boxShadow: 'var(--sapContent_Shadow0)',
    '& > div > div': {
      boxShadow: 'none',
    },
  },
  contentWrapper: {
    paddingBottom: '12px',
  },
  content: {
    whiteSpace: 'normal',
    color: 'var(--sapContent_LabelColor)',
    marginTop: '12px',
  },
  footer: {
    textAlign: 'right',
  },
  datetime: {
    marginRight: '1rem',
    color: 'var(--sapTile_TextColor)',
  },
});

export default function CustomNotification({ notification, onCloseFunction, onClickFunction }) {
  const { t } = useTranslation();

  const {
    data: { businessObject, message },
  } = notification;

  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const recievedDateTime = moment(notification.receivedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
  const differenceTime = moment().diff(recievedDateTime);
  const duration = moment.duration(differenceTime);
  const yearDiff = duration.years();
  const daysDiff = duration.days();
  const hoursDiff = duration.hours();
  const minutesDiff = duration.minutes();

  let fromNow = '';
  if (yearDiff > 0) {
    fromNow += yearDiff + ' ' + t('Measure_Year');
  }
  if (daysDiff > 0) {
    fromNow += ' ' + daysDiff + ' ' + t('Measure_Day');
  }
  if (hoursDiff > 0) {
    fromNow += ' ' + hoursDiff + ' ' + t('Measure_Hour');
  }
  if (minutesDiff > 0) {
    fromNow += ' ' + minutesDiff + ' ' + t('Measure_Minute');
  }

  if (fromNow) {
    fromNow += ' ' + t('Measure_Suffix_Ago');
  } else {
    fromNow = t('Measure_Just_Now');
  }

  let assignmentHeader,
    boId,
    assignorFirstName,
    assignorLastName,
    assignmentText,
    comment,
    detailsPageUrl,
    priority;

  try {
    ({
      boId,
      assignorFirstName,
      assignorLastName,
      assignmentText,
      comment,
      detailsPageUrl,
      assignmentHeader,
      priority,
    } = JSON.parse(message));
    if (businessObject === 'ASSIGN_USER') {
      assignmentHeader = `Return Order ${boId}`;
    }
  } catch {
    assignmentText = message;
  }

  const showAvatar = !!assignorFirstName && !!assignorLastName;

  return (
    <NotificationListItem
      className={classes.notificationStyle}
      titleText={assignmentHeader || businessObject}
      priority={Priority.Medium}
      footnotes={
        <FlexBox justifyContent='SpaceBetween'>
          <div className={classes.datetime}>{fromNow}</div>
          {!!detailsPageUrl && (
            <Link href={detailsPageUrl} onClick={(event) => onClickFunction(event, notification)}>
              {t('Header_Notification_Show_More')}
            </Link>
          )}
        </FlexBox>
      }
      avatar={
        showAvatar ? (
          <Avatar
            backgroundColor='Accent6'
            initials={`${assignorFirstName?.charAt(0)}${assignorLastName?.charAt(0)}`.toUpperCase()}
            shape='Circle'
            size='XS'
            tooltip={`${assignorFirstName} ${assignorLastName}`}
          />
        ) : undefined
      }
      showClose
      onClose={() => onCloseFunction(notification)}
      wrappingType={WrappingType.Normal}
      read={false}
    >
      <Icon
        name='message-warning'
        style={{
          position: 'absolute',
          top: '1rem',
          left: showAvatar ? '3.75rem' : '1.75rem',
          backgroundColor: 'var(--sapObjectHeader_Background)',
          color:
            priority === 'CRITICAL'
              ? 'var(--sapNegativeElementColor)'
              : 'var(--sapCriticalElementColor)',
        }}
      />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{assignmentText}</div>
        <div className={classes.content}>{comment}</div>
      </div>
    </NotificationListItem>
  );
}
