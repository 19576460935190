/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef } from 'react';
import { Button } from '@ui5/webcomponents-react';
import { ButtonDesign } from '@ui5/webcomponents-react';
import eurekaMgrs from '@eureka/ui-managers';
import EurekaComponents from 'eureka';
import { css } from '@emotion/css';

const logoutFormContainer = css`
  height: 135px;
  padding: 0 0.2rem;
`;

const headerContainer = css`
  border-bottom: 0.0625rem solid var(--sapPageFooter_BorderColor, #d9d9d9);
  padding: 0 0.5rem;
`;

const contentContainer = css`
  padding: 1rem;
`;

const footerContainer = css`
  border-top: 0.0625rem solid var(--sapPageFooter_BorderColor, #d9d9d9);
  padding: 0.5rem 0.2rem 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const { useTranslation } = EurekaComponents.I18nProvider;
const { getConfig } = eurekaMgrs.ConfigManager;

const LogoutForm = ({ eventBus }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { auth_provider, auth_method } = getConfig('user') || {};

  return (
    <div className={logoutFormContainer}>
      <header className={headerContainer}>
        <div>
          <h3>{t('Logout_Confirmation_Title')}</h3>
        </div>
      </header>
      <div className={contentContainer}>
        <div>
          <form ref={formRef} action="/auth/logout" method="post">
            <input type="hidden" name="auth_provider" value={auth_provider} />
            <input type="hidden" name="auth_method" value={auth_method} />
          </form>
          {t('Logout_Confirmation_Msg')}
        </div>
      </div>
      <footer className={footerContainer}>
        <Button
          data-testid="di-logout"
          style={{ margin: '0 9px' }}
          design={ButtonDesign.Emphasized}
          onClick={() => {
            if (formRef.current) {
              eventBus.emit('close-websocket', '', {
                type: 'logout',
                data: {},
              });
              setTimeout(() => {
                formRef.current.submit();
              }, 2000);
              eventBus.emit('dialog-show-hide', '', {
                type: 'confirm',
                data: {
                  showDialog: false,
                },
              });
            }
          }}
        >
          {t('Logout')}
        </Button>
        <Button
          style={{ margin: '0 9px' }}
          onClick={() => {
            eventBus.emit('dialog-show-hide', '', {
              type: 'cancel',
              data: {
                showDialog: false,
              },
            });
          }}
          design={ButtonDesign.Transparent}
        >
          {t('Cancel')}
        </Button>
      </footer>
    </div>
  );
};

export default LogoutForm;
