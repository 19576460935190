import { SCREEN_L_SIZE } from '../../../common/constants';
const initialState = {
  notification: [],
  showMenu: window.innerWidth >= SCREEN_L_SIZE,
  userPermissions: [],
  needReloadUserPermissions: true,
  fetchUserPermissionsPending: false,
  fetchUserPermissionsError: null,
  config: null,
  needReloadConfig: true,
  fetchConfigPending: false,
  fetchConfigError: null,
  webAssistantLoaded: false,
};

export default initialState;
