export const DateFormatOptions = [
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'MM DD, YYYY',
  'YYYY-MM-DD',
];
export const DateFormatTexts = [
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'MM DD, YYYY',
  'YYYY-MM-DD',
];
export const TimeFormatOptions = ['TWELVE_HOUR', 'TWENTYFOUR_HOUR'];
export const TimeFormatTexts = ['UserProfile_TwelveHour', 'UserProfile_TwentyFourHour'];
