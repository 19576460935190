const getSdkHelpers = () => {
  let sdk;
  const getSDK = () => {
    return sdk;
  };
  const setSDK = (loadedSdk) => {
    sdk = loadedSdk;
  };
  return {
    getSDK,
    setSDK,
  };
};

const SdkHelpers = getSdkHelpers();

export default SdkHelpers;
