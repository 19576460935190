import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';

const { Spinner } = eureka.components;
const { MessageToast, OptionalMessageBox } = eureka.controls;
const {
  initI18n,
  useTranslation,
  getCurrentLanguage,
  setLanguage,
  withTranslation,
  getUi5Language,
} = eureka.I18nProvider;
const { UtcMapping } = eureka.DateManager;
const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;

const {
  addConfig,
  getConfig,
  setCsrfToken,
  getCsrfToken,
  setLanguage: configManagerSetLanguage,
  getLanguage: configManagerGetLanguage,
  updateFeatureToggle,
  getFeatureToggle,
  setDebug,
} = eurekaMgrs.ConfigManager;
const { ProxyHelper } = eurekaMgrs.ProxyManager;
const AppearanceManager = eurekaMgrs.AppearanceManager;

export {
  Spinner,
  MessageToast,
  initI18n,
  useTranslation,
  getCurrentLanguage,
  setLanguage,
  withTranslation,
  getUi5Language,
  UtcMapping,
  eventBus,
  axiosCreate,
  addConfig,
  getCsrfToken,
  setCsrfToken,
  getConfig,
  OptionalMessageBox,
  configManagerSetLanguage,
  configManagerGetLanguage,
  updateFeatureToggle,
  getFeatureToggle,
  ProxyHelper,
  setDebug,
  AppearanceManager,
};
