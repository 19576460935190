import React, { useState, useMemo, useCallback, useLayoutEffect, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import { checkFeaturetoggle, checkPermissions } from '../common/Utils';

const RoutePermission = ({ history, config, settings, user, eventBus, children }) => {
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);

  const allRouterConfigs = useMemo(
    () => config.components.reduce((acc, curr) => acc.concat(curr.config?.routerConfig || []), []),
    [config],
  );

  const getMatchedItem = useCallback(
    (pathname) => {
      for (let item of allRouterConfigs) {
        if (matchPath(pathname, { path: item.path, exact: true })) return item;
      }
      return null;
    },
    [allRouterConfigs],
  );

  useLayoutEffect(() => {
    const pathname = location.pathname;
    const matchedItem = getMatchedItem(location.pathname);
    if (pathname.toLowerCase() === '/' || pathname.toLowerCase() === '/login') {
      setHasPermission(true);
    } else if (!matchedItem) {
      setHasPermission(false);
    } else {
      setHasPermission(checkFeaturetoggle(matchedItem) && checkPermissions(matchedItem));
    }
  }, [location, allRouterConfigs, getMatchedItem]);

  useEffect(() => {
    eventBus.on('on-no-permission', () => {
      setHasPermission(false);
    });
    return () => {
      eventBus.die('on-no-permission');
    };
  }, [eventBus]);

  return hasPermission ? (
    children
  ) : (
    <PageNotFound
      history={history}
      config={config}
      user={user}
      settings={settings}
      eventBus={eventBus}
    ></PageNotFound>
  );
};

export default RoutePermission;
