import { getCsrfToken, getConfig } from '../../../common/eureka';

export default class HeartBeatWebSocket {
  constructor(url, messageHandler, fetchNotification) {
    this._url = url;
    this._messageHandler = messageHandler;
    this._fetchNotification = fetchNotification;
    this._ws = null;
    this._isOpen = this._isOpen.bind(this);
  }

  start() {
    if (!this._ws) {
      this.forceInitWebSocket();
    }
    return this;
  }

  forceInitWebSocket() {
    console.log(`[${new Date().toISOString()}] Force init Websocket connection`);
    if (this._ws) {
      this.close();
      this._ws.onopen = null;
      this._ws.onmessage = null;
      this._ws.onerror = null;
    }

    const { tenantId, id } = getConfig('user') || {};
    this._ws = new WebSocket(this._url, [`${getCsrfToken()}_${tenantId}_${id}`]);
    this._ws.onopen = this._onWebSocketOpen;
    this._ws.onmessage = this._onWebSocketMessage;
    this._ws.onerror = this._onWebSocketError;
  }

  _isOpen(ws) {
    return ws.readyState === ws.OPEN;
  }

  _onWebSocketOpen = () => {
    console.log(`Web Socket Connection to ${this._url} started.`, this._ws.readyState);
    if (this._isOpen(this._ws)) {
      this._fetchNotification();
      this._interval = setInterval(this._startHeartBeat, 10000);
    }
  };

  _onWebSocketError = (error) => {
    setTimeout(() => {
      this.forceInitWebSocket();
    }, 5000);

    console.log(`[${new Date().toISOString()}] Web Socket reconnected because of onerror`);
  };

  _onWebSocketMessage = (evt) => {
    const msg = evt.data;
    if (msg.startsWith('-') && msg.endsWith('-')) {
      // We don't process heartbeat echo message
      return;
    }
    let dataJson = null;
    try {
      dataJson = JSON.parse(evt.data);
      this._messageHandler && this._messageHandler(dataJson);
    } catch (e) {}
  };

  _startHeartBeat = () => {
    if (!this._isOpen(this._ws)) {
      console.log(`[${new Date().toISOString()}] Websocket connection is closed`);
      this.forceInitWebSocket();
    } else {
      try {
        this._ws.send('-' + Math.random() + '-');
      } catch (err) {
        console.error(
          `[${new Date().toISOString()}] Websocket send data, error: ${err.toString()}`,
        );
      }
    }
  };

  close() {
    clearInterval(this._interval);
    this._ws && this._ws.close();
  }
}
