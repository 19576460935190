import React from 'react';
import { ShellBarItem } from '@ui5/webcomponents-react';
import eureka from 'eureka';
import { useSelector } from 'react-redux';

const { useTranslation } = eureka.I18nProvider;

/* istanbul ignore next */
const WebAssistantHelpButton = () => {
  const { t } = useTranslation();
  const { webAssistantLoaded } = useSelector((state) => state.common);

  return (
    <>
      {webAssistantLoaded && (
        <ShellBarItem
          text={t('ShellBar_Help_Button')}
          title={t('ShellBar_Help_Button')}
          onItemClick={null}
          icon="sys-help"
          data-ui5-stable="web-assistance-button"
        />
      )}
    </>
  );
};

export default WebAssistantHelpButton;
