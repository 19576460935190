import eureka from 'eureka';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, SidePanel } from '../common';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import * as actions from './redux/actions';
import Tiles from './Tiles';
const { Spinner } = eureka.components;
const { withTranslation } = eureka.I18nProvider;

const navigatePage = (props, mfe, router) => {
  if (
    props.config.components &&
    (props.config.components || []).filter((c) => c.name === mfe).length > 0
  ) {
    props.history.push(router);
  }
};

export class WelcomePage extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    eventBus: PropTypes.object.isRequired,
  };

  componentDidMount() {
    navigatePage(this.props, 'di-configuration-ui', '/configuration/systems');
  }

  render() {
    const { showMenu } = this.props.common;
    // const { t } = this.props;

    return (
      <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
        <div className="shell-header">
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              eventBus={this.props.eventBus}
              user={this.props.user}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div
              className={
                'fd-app__navigation fd-app__navigation--vertical' +
                (showMenu ? ' show-menu' : ' hide-menu')
              }
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel
                  history={this.props.history}
                  match={this.props.match}
                  config={this.props.config}
                />
              </Suspense>
            </div>
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                <div className="home-page">
                  <Tiles history={this.props.history} config={this.props.config} />
                </div>
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="welcome-page-dialog" />
        <MessageToast key="welcome-page-toast" />
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WelcomePage));
