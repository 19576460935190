import axios from 'axios';
import eureka from 'eureka';
const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axiosWithCSRF = axiosWrapper.instance;

export const fetchFunctions = {
  fetchConfig() {
    return new Promise((resolve, reject) => {
      axios.get('/config.json').then(resolve, reject);
    });
  },
  fetchAuth() {
    return new Promise((resolve, reject) => {
      axios.get('/auth/user').then(resolve, reject);
    });
  },
  fetchCsrf() {
    return new Promise((resolve, reject) => {
      axios({
        url: `/auth/csrf-token`,
        method: 'POST', // or 'PUT'
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        // body: JSON.stringify(data),
      }).then(resolve, reject);
    });
  },
  fetchSettings() {
    return axiosWithCSRF
      .get('/api/user-configuration-plus/business/v1/users/create')
      .then(() =>
        Promise.all([
          axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/me'),
          axiosWithCSRF
            .get('/api/user-configuration-plus/business/v1/users/roles')
            .then(({ data: { roles = [] } }) => roles.map((p) => p.toUpperCase())),
          axiosWithCSRF.get(`/api/feature-toggle-wrapper/business/v1/feature-toggles`),
        ]),
      );
  },
};

export default fetchFunctions;
