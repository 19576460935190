/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import CacheRoute from 'react-router-cache-route';
import { eventBus } from 'src/common/eureka';

const children = []; // children component list

export const rawSetting = { basicSetup: {}, userProfile: {}, companyProfile: {} };
export const mergeSettings = (inputSetting) => {
  const { basicSetup, userProfile, companyProfile } = inputSetting;
  const settings = { ...basicSetup, ...companyProfile };

  delete settings.id;
  if (parseFloat(userProfile.userUpdateState) > 0) {
    Object.keys(userProfile).forEach((key) => {
      if (userProfile[key]) {
        settings[key] = userProfile[key];
      }
    });
  }
  settings.__raw = { ...inputSetting };
  return settings;
};

export function renderRouteConfigV3(routes, contextPath, config, settings, user) {
  // Resolve route config object in React Router v3.

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <CacheRoute
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <CacheRoute
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return children;
}

export const notEmptyStr = (str) => str && str !== '';
