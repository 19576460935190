const loadSDKScript = async (src, async = true, type = 'text/javascript') => {
    return await new Promise((resolve, reject) => {
      try {
        const el = document.createElement('script');
        const container = document.head || document.body;
        el.type = type;
        el.async = async;
        el.src = src;
        el.addEventListener('load', () => {
          resolve({ sdk: window['sdk-react-dsx-everywhere'] });
        });
        el.addEventListener('error', () => {
          reject(new Error(`Failed to load the script ${src}`));
        });
        container.appendChild(el);
      } catch (err) {
        reject(err);
      }
    });
  };
  
  export default loadSDKScript;