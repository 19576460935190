/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import React, { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { mergeSettings, renderRouteConfigV3, notEmptyStr } from './App.helper';
import { ThemeProvider, IllustratedMessage } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-fiori/dist/illustrations/UnableToLoad.js';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import '@eureka/ui-managers/src/styles/layout.css';
import '@ui5/webcomponents-icons/dist/Assets-static';
import store from './common/store';
import routeConfig from './common/routeConfig';
import history from './common/history';
import { getURLParam, TestingLocales } from './common/Utils';
import { MicroFrontend } from './features/common';
import { getRandom, DEFAULT_THEMEID } from './features/common/Utils';
import {
  addConfig,
  setCsrfToken,
  Spinner,
  initI18n,
  setLanguage,
  configManagerSetLanguage,
  configManagerGetLanguage,
  updateFeatureToggle,
  MessageToast,
  eventBus,
  AppearanceManager,
} from './common/eureka';
import RoutePermission from './features/common/RoutePermission';
import WebAssistant from './common/web-assistant/WebAssistant';

const { setThemeId } = AppearanceManager;

let config = null;
let lng = 'en-US';
let themeId = DEFAULT_THEMEID;

export const loader = () => {
  return <div>Loading...</div>;
};

// eslint-disable-next-line arrow-body-style
export const renderError = (msg) => {
  return (
    <IllustratedMessage name="UnableToLoad" titleText={msg} subtitleText=" "></IllustratedMessage>
  );
};

export const MicroFrontendWrapper = ({ history, match, host, name, settings, user }) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({ mfdRouters, history, settings, user }) => {
  const routes = [];
  mfdRouters.forEach((app) => {
    app.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

const onFetchConfigSuccess = ({ manifest, data, setData, setMicroFrontends }) => {
  const { cdn } = manifest.config;
  const shell = manifest['shell-ui'];
  let shellHost = '';
  const microFrontends = [];

  if (shell) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      shellHost = `${cdn}:${shell.port}`;
    } else {
      // https://cdn.eurekasap.io/eureka/shell-ui//dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (shell.location) {
        shellHost = shell.location;
      } else {
        shellHost = `${cdn}/static/${shell.name}/${shell.commit}`;
      }
    }
  } else {
    console.error('Shell config is missed in config.json, please check');
  }
  const i18nNamespaces = { shell: shellHost };

  manifest.components.forEach((comp) => {
    let host = '';
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // http://localhost:2001
      host = `${cdn}:${comp.port}`;
    } else {
      // https://cdn.eurekacloud.io/static/shell-ui/44c6effde2773258a9282458f26e162b2544be5c/
      // https://cdn.eurekasap.io/eureka/settlement-ui/dbbe6d4/
      // eslint-disable-next-line no-lonely-if
      if (comp.location) {
        host = comp.location;
      } else {
        host = `${cdn}/static/${comp.name}/${comp.commit}`;
      }
    }

    // add i18n hosts
    i18nNamespaces[comp.config.app] = host;

    microFrontends.push({
      name: comp.config.app,
      host,
      routers: comp.config.routers,
    });
  });

  config = manifest;
  setData({
    ...data,
    config,
  });

  setMicroFrontends(microFrontends);

  // add app config into config manager
  addConfig('appConfig', config);

  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(i18nNamespaces, {
    debug: false,
    lowerCaseLng: false,
    fallbackLng: 'en-US',
    fallbackNS: 'shell',
    whitelist: false,
    lng, // en-US en-US-sappsd
    load: 'currentOnly',
    defaultNS: 'shell',
    ns: 'shell',
    preload: [lng], // en-US en-US-sappsd
    react: {
      useSuspense: false,
      wait: false,
    },
  });

  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    return setData({
      ...data,
      initializing: false,
    });
  }
};

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

const onFetchAuthSuccess = ({ auth, user }) => {
  const isLoginPage = window.location.href.includes('/login');
  if (auth.status === 200) {
    window.hasLoggedin = true;
    if (isLoginPage) {
      // history.push('/');
      window.location.href = '/';
    }
  }
};

export const onFetchAuthFailed = ({ error, data, setData }) => {
  window.hasLoggedin = false;
  const isLoginPage = window.location.href.includes('/login');
  if (isLoginPage) {
    console.log(`Auth user error: ${error}`);
    setData({
      ...data,
      initializing: false,
    });
  } else {
    if (error.status === 401) {
      // history.push('/login');
      window.location.href = '/login';
    } else {
      setData({ ...data, authUserError: true });
    }
  }

  // throw new Error(auth.statusText);
};

const onFetchCsrfFailed = ({ error, user, data, setData }) => {
  MessageToast.error('Failed to get CSRF token, please contact System Administrator!');
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setData({
    ...data,
    settings: {},
    user: user.current,
    fetchConfigError: null,
  });
};

export const onFetchSettingsFinally = ({ rawSettings, data, setData }) => {
  const testingLng = getURLParam(window.location.search, 'sap-language');
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }
  setThemeId(themeId);
  setTheme(themeId);
  // set new language
  setLanguage(lng);
  configManagerSetLanguage(lng);

  if (Object.keys(rawSettings.current?.basicSetup) <= 0) {
    setTimeout(() => {
      MessageToast.error('Shell_LoadSettingFailed');
    }, 0);
  }
  // set initialization done
  setData((prevData) => ({
    ...prevData,
    initializing: false,
  }));
};

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchSettingsSuccess = ({ results, rawSettings, data, setData, user }) => {
  const userProfile = getDataFromResults({ results, index: 0 });
  userProfile.timeZone =
    userProfile?.timeZone || userProfile?.profileTimeZone || 'America/Los_Angeles';
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile: {},
  };
  const settings = mergeSettings(rawSettings.current);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const currentUser = getDataFromResults({ results, index: 0 });
  addConfig('CurrentUser', currentUser);
  user.current = currentUser;
  addConfig('user', currentUser || {});

  const currentPermissions = results[1];
  addConfig('CurrentUserPermissions', currentPermissions);

  // addConfig('ThemeSetting', currentUser?.themeId);
  updateFeatureToggle(getDataFromResults({ results, index: 2 })?.resultList);
  themeId = currentUser?.themeId || DEFAULT_THEMEID;
  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }

  user.current.databaseUserId = rawSettings.current.basicSetup.id;

  setData({
    ...data,
    settings: { ...settings },
    user: rawSettings.current.userProfile,
    lang: lng,
  });
};

const App = ({ fetchConfig, fetchAuth, fetchCsrf, fetchSettings }) => {
  const [data, setData] = useState({
    initializing: true,
    fetchConfigError: false,
    fetchSettingsError: false,
    authUserError: false,
    config: null,
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState([]);

  const user = useRef(null);
  const rawSettings = useRef({ basicSetup: {}, userProfile: {}, companyProfile: {} });

  useEffect(() => {
    addConfig('application', 'di');
    fetchConfig().then(
      (result) => {
        const manifest = result.data;
        onFetchConfigSuccess({
          manifest,
          data,
          setData,
          setMicroFrontends,
        });
        fetchAuth().then(
          (auth) => {
            onFetchAuthSuccess({ auth, user });
            fetchCsrf()
              .then(
                (result) => setCsrfToken(result?.data?.token),
                (error) => onFetchCsrfFailed({ error, user, data, setData }),
              )
              .finally(() =>
                fetchSettings()
                  .then(
                    (results) => {
                      onFetchSettingsSuccess({
                        results,
                        data,
                        setData,
                        rawSettings,
                        user,
                      });
                    },
                    () =>
                      setData({
                        ...data,
                        settings: {},
                        user: user.current,
                        fetchConfigError: false,
                      }),
                  )
                  .finally(() => onFetchSettingsFinally({ rawSettings, data, setData })),
              );
          },
          (error) => onFetchAuthFailed({ error: error.response, data, setData }),
        );
      },
      (error) => {
        console.error('Error:', error);
        setData({
          ...data,
          initializing: false,
          fetchConfigError: error,
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    eventBus.on('configuration-updated', (message) => {
      if (notEmptyStr(message.key)) {
        rawSettings.current[message.key] = message.data;
        const settings = mergeSettings(rawSettings.current);
        settings.timeZone =
          settings?.timeZone || settings?.profileTimeZone || 'America/Los_Angeles';
        const updatedUser =
          message.key === 'userProfile'
            ? JSON.parse(JSON.stringify(message.data))
            : {
                ...data.user,
              };
        user.current = updatedUser;

        setData({
          ...data,
          settings,
          user: updatedUser,
        });
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    eventBus.on('navigate-page', (path, data) => {
      if (path) {
        history.push(path, data);
      }
    });

    return () => {
      eventBus.die('configuration-updated');
      eventBus.die('navigate-page');
    };
  }, [data]);

  if (data.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (data.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (data.fetchSettingsError) {
    return renderError('Failed to get company or user settings, please refresh page.');
  }

  if (data.initializing) {
    return renderInitializing();
  }

  return renderMfes({ data, user, microFrontends });
};

const renderMfes = ({ data, user, microFrontends }) => {
  const containerRoutes = renderRouteConfigV3(
    routeConfig,
    '/',
    config,
    data.settings,
    user.current,
  );
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: data.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          <RoutePermission
            history={history}
            config={config}
            settings={data.settings}
            user={user.current}
            eventBus={eventBus}
          >
            <WebAssistant lang={configManagerGetLanguage() || 'en-US'} />
            <Switch>
              {microFrontendRoutes}
              {containerRoutes}
            </Switch>
          </RoutePermission>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
