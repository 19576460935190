export const testUrl = 'https://main.test.built-in-support.ondemand.com';
export const prodUrl = 'https://main.live.built-in-support.ondemand.com';

export const getBuiltInSupportConfig = () => {
  if (
    window.location.hostname === 'di.us.di-ics.cloud.sap' ||
    window.location.hostname === 'di.eu.di-ics.cloud.sap'
  )
    return { url: prodUrl, env: 'live' };
  else return { url: testUrl, env: 'test' };
};
