import { createUseStyles } from 'react-jss';

export default createUseStyles({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 1rem 2rem',
  },
});
