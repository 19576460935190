import React from 'react';
import { ShellBarItem } from '@ui5/webcomponents-react';
import eureka from 'eureka';
import { openDSX } from './utils/openDSX';

const { useTranslation } = eureka.I18nProvider;
/* istanbul ignore next */
const BuiltInSupportButton = () => {
  const { t } = useTranslation();
  return (
    <>
      <ShellBarItem
        text={t('Built_In_Support_Button')}
        title={t('Built_In_Support_Button')}
        onClick={() => openDSX(t)}
        icon="headset"
      />
    </>
  );
};

export default BuiltInSupportButton;
