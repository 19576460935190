import { setupsAxios, usersAxios, languagesAxios } from '../axios';
import { LANGUAGES } from '../Utils';

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async (payload) => usersAxios.patch('/me', payload);

export const fetchBasicSetup = async () => setupsAxios.get('/basic-setup');

export const fetchLanguages = async (currentLang) => languagesAxios.get(`/${currentLang}`);

// export const fetchLanguages = async (currentLang) => new Promise((resolve) => resolve(LANGUAGES));

export const fetchTimezones = async () => await (await usersAxios.get('/timezones')).data;
