import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './redux/actions';
import { SideNavigationItem, SideNavigationSubItem } from '@ui5/webcomponents-react';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import SideNavigationObserver from './SideNavigationObserver';
import { getURLParam } from '../../common/Utils';
import { buildSideNavListItems } from './Utils';
import styles from './SidePanel.styles';
import { SCREEN_L_SIZE } from '../../common/constants';

const { getFeatureToggle } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = false;

const useStyles = createUseStyles(styles);

const isDisable = (item) => !!item?.disabled;

const getConfigValueByProp = (config, propName, propValue, valueName) => {
  for (const comp of config.components || []) {
    for (const nav of comp?.config?.sidenav || []) {
      if (nav[propName] === propValue || propValue?.startsWith(nav[propName])) {
        return {
          appName: comp.config.app,
          [valueName]: nav[valueName],
          disabled: isDisable(nav),
        };
      } else if (Array.isArray(nav?.items) && nav.items.length > 0) {
        for (const item of nav.items) {
          if (item[propName] === propValue || propValue?.startsWith(item[propName])) {
            return {
              appName: comp.config.app,
              [valueName]: item[valueName],
              disabled: isDisable(item),
            };
          }
        }
      }
    }
  }
};

function SidePanel(props) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState('home');
  const [appName, setAppName] = useState('');
  const { t } = useTranslation();
  const { showMenu } = props.common || {};

  // set initial sidePanel active route
  useEffect(() => {
    const pathname = props.history.location.pathname;
    handleSetActiveApp(props.config, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // subscribe for subsequent path changes
  useEffect(() => {
    const unlisten = props.history?.listen(({ pathname }) =>
      handleSetActiveApp(props.config, pathname),
    );

    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history, props.config]);

  const handleSetActiveApp = useCallback((config, pathname) => {
    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg && cfg.id) {
        setAppName(cfg.appName);
        setSelectedId(cfg.id);
      } else if (pathname === '/support-page') {
        setAppName('');
        setSelectedId('support');
      } else {
        setAppName('');
        setSelectedId('home');
      }
    }
  }, []);

  const handleNavigationItemClick = (e) => {
    const selected = e.target.selected;
    if (!selected) {
      return;
    }
    handleSelectChange(e.target.id);
  };

  const handleSelectChange = (selectedId) => {
    if (!selectedId) {
      return;
    }
    const config = props.config;

    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';

    if (selectedId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push('/' + testingLngParam);
    } else {
      setSelectedId(selectedId);
      const cfg = getConfigValueByProp(config, 'id', selectedId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        props.history.push(cfg.router + testingLngParam);
      }
    }
    // when the width is less than 1024, after the selection hide the menu
    if (window.innerWidth < SCREEN_L_SIZE) {
      props.actions.hideMenu();
    }
  };

  const renderNavItems = () => {
    const config = props.config;

    const initListItems = [];

    const listItems = buildSideNavListItems({
      initListItems,
      components: config?.components,
      getFeatureToggle,
    }).sort((a, b) => a.order - b.order);

    return listItems.map((item, idx) => {
      if (Array.isArray(item?.items) && item.items.length > 0) {
        return (
          <SideNavigationItem
            onClick={handleNavigationItemClick}
            selected={item.id === selectedId}
            expanded={!!item.items.find((child) => child.id === selectedId)}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            title={t(item.text)}
          >
            {item.items.map((child) => (
              <SideNavigationSubItem
                selected={child.id === selectedId}
                onClick={handleNavigationItemClick}
                key={child.id}
                className={
                  isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                }
                text={t(child.text)}
                icon={child.icon}
                id={child.id}
                data-id={child.id}
                title={t(child.text)}
              />
            ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            selected={item.id === selectedId}
            onClick={handleNavigationItemClick}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            title={t(item.text)}
          />
        );
      }
    });
  };

  return (
    <SideNavigationObserver
      className={classes.sideNavigation}
      collapsed={!showMenu}
      selectedId={selectedId}
      style={{ height: '100%', fontSize: '14px' }}
      onSelectionChange={(e) => {
        handleSelectChange(e.detail.item.id);
      }}
    >
      {renderNavItems()}
    </SideNavigationObserver>
  );
}

SidePanel.propTypes = {
  actions: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
