// import throttle from 'lodash.throttle';
import eureka from 'eureka';
// import { SCREEN_L_SIZE } from './constants';
const { MessageToast } = eureka.controls;

// const handleResize = throttle(() => {
//   if (window.innerWidth >= SCREEN_L_SIZE) {
//     document.body.classList.add('ui5-content-density-compact');
//   } else {
//     document.body.classList.remove('ui5-content-density-compact');
//   }
// }, 300);
// handleResize();

// window.addEventListener('resize', handleResize);

window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason;
  let status = error?.response?.status;
  if (
    /^[4-5]\d{2}/.test(status) &&
    status !== 401 &&
    status !== 403 &&
    !error.noGlobalNotification
  ) {
    MessageToast.error(error?.response?.data?.message || 'Request failed!');
  }
});
