import axiosWrapper from './../../axios/axiosWrapper';

export const getProductConfig = async () => {
  const { data } = await axiosWrapper.fetchAuth();
  return {
    PPMSIDs: ['73554900100200017033','73554900100100009927'],
    hcpSubAccountID: data.zid,
  };
};

export const getApplicationConfig = function () {
  return {
    applicationName: 'DI',
    achComponent: 'IS-ICS-DI',
  };
};

export const getRecommendedConfig = function (t) {
  return [
    {
      text: t('Administration_Guide'),
      description: t('Administration_Guide_description'),
      url: 'https://help.sap.com/docs/latest/925366f331c54ee88e2b61ddae0be9fc',
    },

    {
      text: t('Security_Guide'),
      description: t('Security_Guide_description'),
      url: 'https://help.sap.com/docs/latest/91bd4ea4234d4cdea375529f30a8ecaf',
    },

    {
      text: t('Available_Data_Entities'),
      description: t('Available_Data_Entities_description'),
      url: 'https://help.sap.com/docs/latest/48fd9084cdb14e3fbc9e1826fac76d29',
    },

    {
      text: t('Whats_New'),
      description: t('Whats_New_description'),
      url: 'https://help.sap.com/whats-new/ef4e2e1e67ef40e9854cd86d9d0df52c',
    },
  ];
};
