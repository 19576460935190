import React from 'react';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import { useTranslation } from 'src/common/eureka';

export default (props) => {
  const { t } = useTranslation();
  // console.log(t);
  let errorType = 'general';
  const uuid = props.match.params.id;
  if (!uuid) {
    errorType = 'general';
  } else if (
    /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/gi.test(uuid)
  ) {
    //00001513-1212-EFDE-1523-785DEABCD122
    errorType = uuid;
  } else if ('user-not-found' === uuid.trim()) {
    errorType = 'user-not-found';
  } else if ('tenant-not-found' === uuid.trim()) {
    errorType = 'tenant-not-found';
  } else {
    errorType = uuid;
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div style={{ width: '300px', height: '200px' }}>
        <MessageStrip hideCloseButton={true} hideIcon={false} design={MessageStripDesign.Negative}>
          {t('Error_ErrorHappened')}
          <br />
          {t('Error_ErrorType')}: {errorType}
          <br />
          {t('Error_ErrorTips')}
        </MessageStrip>
      </div>
    </div>
  );
};
