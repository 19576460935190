import { COMMON_SHOW_MENU, COMMON_HIDE_MENU, COMMON_SET_WEB_ASSISTANT_LOADED } from './constants';

export function showMenu() {
  return (dispatch) => {
    dispatch({
      type: COMMON_SHOW_MENU,
      data: true,
    });
  };
}

export function hideMenu() {
  return (dispatch) => {
    dispatch({
      type: COMMON_HIDE_MENU,
      data: false,
    });
  };
}

export function setWebAssistantLoaded() {
  return (dispatch) => {
    dispatch({
      type: COMMON_SET_WEB_ASSISTANT_LOADED,
      data: true,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_SHOW_MENU:
      return {
        ...state,
        showMenu: action.data,
      };
    case COMMON_HIDE_MENU:
      return {
        ...state,
        showMenu: action.data,
      };
    case COMMON_SET_WEB_ASSISTANT_LOADED:
      return {
        ...state,
        webAssistantLoaded: action.data,
      };
    default:
      return state;
  }
}
