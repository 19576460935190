import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip, ToastPlacement, MessageStripDesign, Toast } from '@ui5/webcomponents-react';
import EurekaComponents from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

const { useTranslation } = EurekaComponents.I18nProvider;
const useStyles = createUseStyles(({ parameters }) => ({
  toast: {
    '--_ui5_toast_background': 'transparent',
    '--_ui5_toast_shadow': 'none',
  },
  wrapper: {
    display: 'flex',
  },
}));
const eventBus = eurekaMgrs.eventBus;
const MessageStripTypeEnum = {
  success: MessageStripDesign.Positive,
  warn: MessageStripDesign.Warning,
  warning: MessageStripDesign.Warning,
  info: MessageStripDesign.Information,
  error: MessageStripDesign.Negative,
  default: MessageStripDesign.Information,
};

const ToastPlugin = () => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const classes = useStyles();

  const [toastProps, setToastProps] = useState({
    toastType: 'default',
    toastDuration: 3000,
    toastContent: '',
    toastPlacement: ToastPlacement.BottomCenter,
    toastIcon: null,
    iconContainerStyles: {},
    iconStyles: {},
  });

  const messageHandler = (message) => {
    /* istanbul ignore next */
    if (message.content) {
      setToastProps({
        toastType: message.type,
        toastContent: message.content,
        toastDuration: message.duration,
        toastPlacement: message.placement || ToastPlacement.BottomCenter,
        messageStripType: MessageStripTypeEnum[message.type],
        noCloseButton: message.noCloseButton,
      });
    }
  };
  /* istanbul ignore next */
  useEffect(() => {
    eventBus.on('on-message-toast', messageHandler);
    return () => {
      eventBus.detach('on-message-toast', messageHandler);
    };
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    let current = toastRef && toastRef.current;
    if (current && toastProps.toastContent && toastProps.toastContent !== '') {
      current.show();
      setTimeout(() => {
        const toastRoot = toastRef.current.shadowRoot.querySelector('.ui5-toast-root');
        if (toastRoot) {
          toastRoot.style.maxWidth = '60rem';
        }
      }, 50);
    }
    return () => {
      current = null;
    };
  }, [toastProps]);

  const onToastClose = React.useCallback(() => {
    toastRef?.current.removeAttribute('open');
  }, [toastRef]);

  return (
    <Toast
      duration={toastProps.toastDuration}
      placement={toastProps.toastPlacement}
      ref={toastRef}
      className={classes.toast}
    >
      <div className={classes.wrapper}>
        <MessageStrip
          hideCloseButton={toastProps.noCloseButton}
          hideIcon={false}
          design={toastProps.messageStripType}
          onClose={onToastClose}
        >
          {t(toastProps.toastContent)}
        </MessageStrip>
      </div>
    </Toast>
  );
};

export default ToastPlugin;
