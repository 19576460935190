import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
} from '@ui5/webcomponents-react';
import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
  MessageToast,
  UtcMapping,
  AppearanceManager,
} from 'src/common/eureka';
import { closeShellDialog } from '../../plugins/dialog.plugin';
import { fetchLanguages, fetchMe, patchMe } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import UserAccount from './UserAccount';
import UserAppearance from './UserAppearance';
import { TimeFormatOptions, TimeFormatTexts } from './options';

import { getThemeById, DEFAULT_THEMEID } from '../../common/Utils';

const { getThemeId } = AppearanceManager;
const UserProfileAccountItemId = 'user-profile_account';
const UserProfileAppearanceItemId = 'user-profile_appearance';
const UserProfileLanguageRegionItemId = 'user-profile_language_region';

const initialData = {
  userName: '',
  pictureId: null,
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
};

const TimeZoneOptions = Object.keys(UtcMapping).map((key) => {
  return { ...UtcMapping[key], key };
});

const getFullUserName = (userName) => {
  if (userName && typeof userName === 'object') {
    return userName.familyNameFirst
      ? userName.lastName + ' ' + userName.firstName
      : userName.firstName + ' ' + userName.lastName;
  }
};

const borderStyle = '1px solid var(--sapPageHeader_BorderColor)';

export default function UserProfile({ config, eventBus }) {
  const { t } = useTranslation(process.env.APP_NAME);
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState('user-profile_account');
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);

  const notifyOthers = useCallback(
    (user, languageChanged, themeChanged) => {
      try {
        if (languageChanged) {
          eventBus?.emit('i18n-update', 'user language', user?.language || 'en-US');
        }
        if (themeChanged) {
          const themeId = user?.themeId || DEFAULT_THEMEID;
          eventBus?.emit('appearance-update', 'user theme', { themeId });
        }
        eventBus?.emit('configuration-updated', 'user profile', {
          key: 'userProfile',
          data: user,
        });
      } catch (e) {
        console.log(e);
      }
    },
    [eventBus],
  );

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const results = await Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())]);
      const me = results[0].data;
      setLanguage(me.language);
      setData({
        ...me,
      });
      setOriginalData({
        ...me,
      });
      setLanguages(results[1].data || []);
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCancel = useCallback(() => {
    closeShellDialog();
    setData({
      ...originalData,
    });
  }, [originalData]);

  const onDataPropChange = useCallback(
    (value, propName) => {
      if (propName.indexOf('.') > 0) {
        const [objName, subPropName] = propName.split('.');
        setData({
          ...data,
          [objName]: {
            ...data[objName],
            [subPropName]: value,
          },
        });
      } else {
        setData({
          ...data,
          [propName]: value,
        });
      }
    },
    [data],
  );

  const compareData = useCallback((data, originalData) => {
    const differ = {};
    let languageChanged = false;
    let themeChanged = false;
    Object.keys(data).forEach((key) => {
      if (originalData[key] !== data[key]) {
        differ[key] = data[key];
        if (key === 'language') {
          languageChanged = true;
        }
        if (key === 'themeId') {
          themeChanged = true;
        }
      }
    });
    if (Object.keys(differ).length > 0) {
      let current = parseInt(data.userUpdateState);
      current = isNaN(current) ? 1 : current;
      differ.userUpdateState = current + 1;
    }
    return { differ, languageChanged, themeChanged };
  }, []);

  const handleSave = useCallback(() => {
    const { differ, languageChanged, themeChanged } = compareData(data, originalData);
    setLoading(true);
    return patchMe({ ...differ })
      .then((result) => {
        const user = result.data;
        setOriginalData({ ...user });
        setData({ ...user });
        notifyOthers(user, languageChanged, themeChanged);
        if (languageChanged) {
          setLanguage(data.language);
          configManagerSetLanguage(data.language);
        }
        MessageToast.success(t('UserProfile_Msg_UpdateSuccess'));
        setLoading(false);
        closeShellDialog();
      })
      .catch(() => {
        setData({
          ...originalData,
        });
        MessageToast.error(t('UserProfile_Msg_UpdateFailed'));
        setLoading(false);
      });
  }, [compareData, data, notifyOthers, t, originalData]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <Title level={TitleLevel.H3} style={{ padding: '16px 24px', fontSize: '16px' }}>
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: borderStyle, borderTop: borderStyle }}></div>
      <div style={{ display: 'flex', width: '680px', height: '492px', padding: 0 }}>
        <List
          style={{ width: '221px' }}
          mode={ListMode.SingleSelect}
          noDataText={t('UserProfile_Msg_NoDataAvailable')}
          onItemClick={(evt) => {
            setCurrentItem(evt.detail.item.id);
          }}
        >
          <StandardListItem
            id={UserProfileAccountItemId}
            icon="employee"
            selected={currentItem === UserProfileAccountItemId}
            description={getFullUserName(data.userName, data.familyNameFirst)}
          >
            {t('UserProfile_UserAccount')}
          </StandardListItem>
          <StandardListItem
            icon="palette"
            id={UserProfileAppearanceItemId}
            selected={currentItem === UserProfileAppearanceItemId}
            description={getThemeById(getThemeId())?.displayName}
          >
            {t('UserProfile_Appearance', 'Appearance', { type: 'mit', desc: '' })}
          </StandardListItem>
          <StandardListItem
            icon="globe"
            id={UserProfileLanguageRegionItemId}
            selected={currentItem === UserProfileLanguageRegionItemId}
            description={`${(
              (data.language && data.language.split('-')[0]) ||
              '...'
            ).toUpperCase()} | ${
              t(TimeFormatTexts[TimeFormatOptions.indexOf(data.timeFormat)] || '...') || '...'
            }`}
          >
            {t('UserProfile_LanguageAndRegion')}
          </StandardListItem>
        </List>
        <section
          style={{
            flexGrow: 1,
            width: '460px',
            padding: '1rem',
            background: 'var(--sapNeutralBackground)',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {currentItem === UserProfileAccountItemId && (
            <UserAccount
              data={data}
              timezones={TimeZoneOptions}
              onDataPropChange={onDataPropChange}
            />
          )}
          {currentItem === UserProfileAppearanceItemId && (
            <UserAppearance onDataPropChange={onDataPropChange} />
          )}
          {currentItem === UserProfileLanguageRegionItemId && (
            <LanguageAndRegion
              data={data}
              languages={languages}
              onDataPropChange={onDataPropChange}
            />
          )}
        </section>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('Save')}
        </Button>
        <Button
          style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('Cancel')}
        </Button>
      </div>
      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}
