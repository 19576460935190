import { configManagerGetLanguage, AppearanceManager } from '../../eureka';
import { getBuiltInSupportConfig } from './../bisConfig';
import { getProductConfig, getApplicationConfig, getRecommendedConfig } from './../bisContext';
import SdkHelpers from './SDKHelpers';
import loadSDKScript from './loadSDKScript';

const { url, env } = getBuiltInSupportConfig();

const getSDK = async () => {
  // BIS SDK - 1.1 Load SDK
  /* istanbul ignore next */
  if (!SdkHelpers.getSDK()) {
    const { sdk } = await loadSDKScript(`${url}/dsx-everywhere-sdk/sdk-react-dsx-everywhere.js`);
    SdkHelpers.setSDK(sdk);
    return sdk;
  }
  return SdkHelpers.getSDK();
};

export const openDSX = async (t) => {
  const sdk = await getSDK();
  if (sdk) {
    // BIS SDK - 1. Initialize SDK && Create Instance && Open
    if (!sdk.isInitialized()) {
      console.log('open DSX first time');

      sdk.create({
        version: 'V1',
        targetEnvironment: env,
        useIcon: false,
        topOffset: '44px',
        getProductContext: async function () {
          const { hcpSubAccountID, PPMSIDs } = await getProductConfig();
          return new Promise((resolve) => {
            resolve({
              productVersion: 'DATA INGESTION ICS 1.0',
              language: configManagerGetLanguage(),
              PPMSIDs,
              systemRole: 'DIICS',
              hcpSubAccountID,
            });
          });
        },

        getApplicationContext: function () {
          const { applicationName, achComponent } = getApplicationConfig();
          return new Promise((resolve) => {
            resolve({
              applicationName,
              achComponent,
            });
          });
        },
        getUsersTheme: function () {
          return new Promise((resolve) => {
            resolve({
              theme: AppearanceManager.getThemeId(),
            });
          });
        },

        getRecommendedLinks: function () {
          return new Promise((resolve) => {
            resolve({
              recommendedLinks: getRecommendedConfig(t),
            });
          });
        },
      });
      sdk.openWindow();
    } else {
      if (sdk.isLangUpdated) {
        sdk.refreshContext(() => {
          return {
            recommendedLinks: getRecommendedConfig(t),
          };
        });
        sdk.isLangUpdated = false;
      }

      sdk.isWindowOpened() ? sdk.closeWindow() : sdk.openWindow();
    }
  }
};
